// eslint-disable-next-line
import { useState, useEffect, useCallback } from "react";

import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box"
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader"
import Heading from "@amzn/meridian/heading";
//import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button"
import Toaster from "@amzn/meridian/toaster"
import Alert from "@amzn/meridian/alert"
import Divider from "@amzn/meridian/divider"
import Link from "@amzn/meridian/link"
import Table, {
  TableRow,
  TableCell,
} from "@amzn/meridian/table"
import Select, { SelectOption } from "@amzn/meridian/select";
import Toggle from "@amzn/meridian/toggle"
import Pagination from "@amzn/meridian/pagination"

import type { AuthDetails } from "./auth/midwayAuth";
import { Auth } from "aws-amplify";
import { DynamoDB } from "aws-sdk";


const initDDB = async () => {
  var ddb = new DynamoDB({ apiVersion: '2012-08-10' });
  ddb.config.update({
    region: 'us-east-1',
    credentials: await Auth.currentCredentials()
  });
  return ddb;
}

type ConfigurationProps = {
  userInfo: AuthDetails,
  setPageTitle: Function,
  regionName: string
}
/*
const objectMap = (obj, fn) => {
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  )
}
*/
const RowToggle = ({
  check,
  network,
  warehouseId,
  carrierKey,
  changer
}) => {
  const [enabled, setEnabled] = useState<boolean>(check);
  const onEnabledChange = value => {
    setEnabled(value)
    changer(network, warehouseId, carrierKey, value)
  }

  return <Toggle checked={enabled} onChange={onEnabledChange} />
}

const RowSelect = ({
  initValue,
  network,
  warehouseId,
  carrierKey,
  changer
}) => {
  const [timeLogic, setTimeLogic] = useState(initValue);
  const onTimeLogicChange = value => {
    setTimeLogic(value)
    changer(network, warehouseId, carrierKey, value)
  }

  return <Select
    value={timeLogic}
    onChange={onTimeLogicChange}
    placeholder="None Selected"
    width={200}
  >
    <SelectOption value="LVSD" label="LVSD" />
    <SelectOption value="EVSD" label="EVSD" />
  </Select>
}

const itemsPerPage = 7;

export const ConfigurationView = (props: ConfigurationProps) => {

  useEffect(() => {
    props.setPageTitle("Standing Appointment Automation");
    setIsDataLoaded(false);
    setVendorList([]);
    setNetworkList([]);
    setWarehouseList([]);
    setCarrierDict({});
    setVendorDict({});
    setNetwork("");
    setSubmitted(false);
    setToasts([]);
    setWarehouse([]);
    setCurrentPage(1);
    setNumberOfPages(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.regionName]);

  let toastId = 0

  const [currentPage, setCurrentPage] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(1)
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const [vendorList, setVendorList] = useState<Object[]>([]);
  const [networkList, setNetworkList] = useState<Object[]>([]);
  const [warehouseList, setWarehouseList] = useState<Object[]>([]);
  const [carrierDict, setCarrierDict] = useState<{}>({});
  const [vendorDict, setVendorDict] = useState<{}>({});
  const [vendorName, setVendorName] = useState("");
  const [network, setNetwork] = useState("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [toasts, setToasts] = useState<any[]>([])
  const [warehouse, setWarehouse] = useState<Object[]>([]);

  useEffect(() => {
    const getConfigurationData = async () => {
      setIsDataLoaded(false);
      global.AWS.config.region = 'us-east-1';
      const ddb = await initDDB();

      let params = {
        TableName: "auto_appointment_vendor_details_prod",
        FilterExpression: "regionId = :regionName",
        ExpressionAttributeValues: {
          ":regionName": { S: props.regionName },
        },
        ConsistentRead: true
      }
      const vendorData = await ddb.scan(params).promise();

      const vendorNameList = (vendorData.Items ?? []).map(e => {
        return {
          regionId: e.regionId.S,
          warehouseId: e.warehouseId.S,
          vendorCode: e.vendorCode.S,
          vendorName: (e.vendorName && e.vendorName.S && e.vendorName.S.trim() !== "") ? e.vendorName.S : "undefined",
          network: e.network.S,
          carrierKey: e.carrierKey.S
        }
      });

      let resultDict = {};

      let configParams = {
        TableName: "auto_appointment_carrier_configurations_prod",
        FilterExpression: "regionId = :regionName",
        ExpressionAttributeValues: {
          ":regionName": { S: props.regionName },
        },
        ConsistentRead: true
      }

      const configData = await ddb.scan(configParams).promise();

      const configList = (configData.Items ?? []).map(e => {
        return {
          carrierKey: e.carrierKey.S,
          vendorCodeList: e.carrierId.S,
          enabled: e.enabled.BOOL,
          timeLogic: e.timeLogic.S,
          validFrom: (e.validFrom && e.validFrom.S) ? e.validFrom.S : new Date().toISOString(),
        }
      });

      let configDict = {};

      (configList ?? []).forEach(e => {
        if (e.carrierKey != null) {
          configDict[e.carrierKey] = e;
        }
      });

      let vendorDict = {};

      (vendorNameList ?? []).forEach(e => {
        if (e.carrierKey != null) {
          vendorDict[e.carrierKey] = (vendorDict[e.carrierKey] || []).concat(e.vendorName)
        }
      });

      (vendorNameList ?? []).forEach(e => {
        if (e.carrierKey != null && e.network != null && e.warehouseId != null && e.carrierKey in configDict) {
          if (!(e.network in resultDict)) {
            resultDict[e.network] = {};
          }
          e["enabled"] = configDict[e.carrierKey]["enabled"]
          e["timeLogic"] = configDict[e.carrierKey]["timeLogic"]
          e["vendorCodeList"] = configDict[e.carrierKey]["vendorCodeList"]
          e["validFrom"] = configDict[e.carrierKey]["validFrom"]

          if (!(e["vendorName"] in resultDict[e.network])) {
            resultDict[e.network][e["vendorName"]] = {};
          }
          if (!(e.warehouseId in resultDict[e.network][e["vendorName"]])) {
            resultDict[e.network][e["vendorName"]][e.warehouseId] = {};
          }
          resultDict[e.network][e["vendorName"]][e.warehouseId][e.carrierKey] = e;
        }
      })

      if (configData) {
        setNetworkList(Object.keys(resultDict).sort());
        setCarrierDict(resultDict);
        setVendorDict(vendorDict);
        setIsDataLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getConfigurationData();
  }, [props.regionName]);

  const navigateNetwork = useCallback(value => {
    setNetwork(value);
    setVendorList(Object.keys(carrierDict[value]).sort());
    setVendorName("");
    setWarehouseList([]);
    setWarehouse([]);
    setNumberOfPages(0);
    setCurrentPage(1);
  }, [carrierDict]);

  const navigateVendor = useCallback(value => {
    setVendorName(value);
    setWarehouseList(Object.keys(carrierDict[network][value]).sort());
    setWarehouse(Object.keys(carrierDict[network][value]).sort());
    setNumberOfPages(Math.ceil(Object.keys(carrierDict[network][value]).length / itemsPerPage))
    setCurrentPage(1)
  }, [network, carrierDict]);

  const navigateWarehouse = useCallback(value => {
    if (value.includes("None")) {
      setWarehouse([])
      setNumberOfPages(0)
    } else {
      setWarehouse(value);
      setNumberOfPages(Math.ceil(value.length / itemsPerPage))
    }

  }, []);

  const changeEnabled = useCallback((network, warehouseId, carrierKey, value) => {
    vendorDict[carrierKey].map((vendorName) => (
      carrierDict[network][vendorName][warehouseId][carrierKey]["enabled"] = value
    ))
  }, [carrierDict, vendorDict]);

  const changeTimeLogic = useCallback((network, warehouseId, carrierKey, value) => {
    vendorDict[carrierKey].map((vendorName) => (
      carrierDict[network][vendorName][warehouseId][carrierKey]["timeLogic"] = value
    ))
  }, [carrierDict, vendorDict]);

  const saveOnClick = async (warehouseId, carrierKey, vendorCodeList) => {
    setSubmitted(true);
    const ddb = await initDDB();
    let params = {
      TableName: "auto_appointment_carrier_configurations_prod",
      Item: {
        "carrierKey": { S: carrierKey },
        "regionId": { S: props.regionName },
        "warehouseId": { S: warehouseId },
        "carrierId": { S: vendorCodeList },
        "timeLogic": { S: carrierDict[network][vendorName][warehouseId][carrierKey]["timeLogic"] },
        "enabled": { BOOL: carrierDict[network][vendorName][warehouseId][carrierKey]["enabled"] },
        "validFrom": { S: carrierDict[network][vendorName][warehouseId][carrierKey]["validFrom"] },
        "network": { S: network },
        "last_updated_by": { S: props.userInfo.username },
        "last_updated_datetime": { N: new Date().valueOf().toString() },
      },
      ReturnConsumedCapacity: "TOTAL"
    }
    console.log(carrierKey)

    ddb.putItem(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else console.log(data);
    });

    onOpenToast();
    setSubmitted(false);
  };

  const onOpenToast = async () => {
    setToasts(toasts.concat({ id: ++toastId + '', timeout: 3000 }))
  }

  const onCloseToast = async (id) => {
    setToasts(toasts.filter(t => t.id !== id))
  }

  const redirectOnClick = useCallback(() => {
    if (props.regionName === 'EU') {
      window.location.replace("https://unified-portal-eu.corp.amazon.com/");
    } else {
      window.location.replace("https://unified-portal-na.corp.amazon.com/");
    }
  }, [props.regionName]);

  return isDataLoaded ?
    <>
      <Toaster toasts={toasts} alignmentHorizontal="end" onCloseToast={onCloseToast}
      >
        {toast => <Alert toast={true}
          type="success"
        >Configuration Change Successful</Alert>}
      </Toaster>
      <Box type="outline" spacingInset="400">
        <Heading level={5}>Appointment Configuration</Heading>
        <Divider />
        <Row>
          <Column>
            <Text type="b300">In this page, you can view and alter the standing appointment configuration set for the vendor in the network.</Text>
            <Text type="b300">For additional information about gaining access, feature requests, or filing a ticket, go to <Link href="https://w.amazon.com/bin/view/GSF/SupplyChain/IRIS/Projects/AutoAppointmentScheduler">wiki page.</Link></Text>
            <Button onClick={redirectOnClick} size='small' type="secondary">
              Want to change Standing Appointments?
              Head to Unified Portal
            </Button>
          </Column>


        </Row>
      </Box>
      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="medium">
          <Row>
            <Column width="30%" height="10%">
              {networkList ?
                <Column spacing="medium">
                  <Heading level={5}>Network:</Heading>
                  <Select
                    value={network}
                    id="network-select"
                    onChange={navigateNetwork}
                    placeholder="Select a network"
                  >
                    {networkList.map(element => (
                      <SelectOption key={element.toString()} label={element.toString()} value={element.toString()} />
                    ))}
                  </Select>
                </Column>
                : <Loader />}
            </Column>
            <Column width="30%" height="10%">
              <Column spacing="medium">
                <Heading level={5}>Vendor Name:</Heading>
                <Select
                  value={vendorName}
                  id="vendor-select"
                  onChange={navigateVendor}
                  placeholder="Select a vendor"
                  disabled={vendorList.length === 0}
                >
                  {vendorList.map((element) => (
                    <SelectOption key={element.toString()} label={element.toString()} value={element.toString()} />
                  ))}
                </Select>
              </Column>
            </Column>
            <Column width="30%" height="10%">
              <Column spacing="medium">
                <Heading level={5}>Warehouse:</Heading>
                <Select
                  value={warehouse}
                  id="warehouse-select"
                  onChange={navigateWarehouse}
                  placeholder="Select a vendor"
                  disabled={warehouseList.length === 0}
                >
                  <SelectOption key="None" value="None" label="None" />
                  {warehouseList.map((element) => (
                    <SelectOption key={element.toString()} label={element.toString()} value={element.toString()} />
                  ))}
                </Select>
              </Column>
            </Column>
          </Row>
          {warehouse ?
            <>
              <Table headerRows={1}>
                <TableRow>
                  <TableCell>Network</TableCell>
                  <TableCell>Warehouse</TableCell>
                  <TableCell>Vendor</TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell>Delivery Pattern</TableCell>
                  <TableCell>Submit</TableCell>
                </TableRow>
                {warehouse.slice(firstVisibleIndex, lastVisibleIndex).map(element =>

                  Object.entries(carrierDict[network][vendorName][element]).map(([carrierKey, value]) => {
                    // iterate over data in element. element is a dictionary of carrier config with carrierId as key
                    return (
                      <TableRow key={carrierKey.toString()}>

                        <TableCell>{network}</TableCell>
                        <TableCell>{element.toString()}</TableCell>
                        <TableCell>{carrierDict[network][vendorName][element][carrierKey]["vendorCodeList"]}</TableCell>
                        <TableCell><Column width="100%">
                          <RowToggle
                            check={carrierDict[network][vendorName][element][carrierKey]["enabled"]}
                            network={network}
                            warehouseId={element.toString()}
                            carrierKey={carrierKey}
                            changer={changeEnabled}
                          />
                        </Column></TableCell>
                        <TableCell>
                          <RowSelect
                            initValue={carrierDict[network][vendorName][element.toString()][carrierKey]["timeLogic"]}
                            network={network}
                            warehouseId={element.toString()}
                            carrierKey={carrierKey}
                            changer={changeTimeLogic}
                          />
                        </TableCell>
                        <TableCell>
                          <Column spacingInset="400" alignmentHorizontal="start">
                            <Button onClick={() => saveOnClick(element.toString(), carrierKey, carrierDict[network][vendorName][element][carrierKey]["vendorCodeList"])} disabled={submitted}>Save Changes</Button>
                          </Column>
                        </TableCell>


                      </TableRow>
                    )
                  })

                )}



              </Table>
              {numberOfPages > 1 ?
                <Pagination
                  showSkipArrows={true}
                  numberOfPages={numberOfPages}
                  onChange={setCurrentPage}
                  currentPage={currentPage}
                />
                : <></>
              }

            </>
            : <></>
          }
        </Box >
      </Row >
    </>
    : <Loader />

}
