/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:423cf796-189f-44b6-b2be-c1f663eb563c",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_70IAuTqEC",
  "aws_user_pools_web_client_id": "2jtkbns1iqn6bkadbf329aruna",
  "oauth": {
    "domain": "autoappointmentsched-midway-alpha.auth.us-east-1.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://dev-dsk-donchung-1d-f8212f78.us-east-1.amazon.com:3000/",
    "redirectSignOut": "https://dev-dsk-donchung-1d-f8212f78.us-east-1.amazon.com:3000/",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ]
};


export default awsmobile;
