/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cloud_logic_custom": [
    {
      "name": "AutoAppointmentAPI",
      "endpoint": "https://9pybcsu1if.execute-api.us-east-1.amazonaws.com/prod",
      "region": "us-east-1"
    }
  ],
  "aws_cognito_identity_pool_id": "us-east-1:75e6551c-55d4-4abb-a4ad-339eee004362",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_K1ES36JcL",
  "aws_user_pools_web_client_id": "q7vf57ubnu5q5ee46rrg1h0l5",
  "oauth": {
    "domain": "autoappointmentsched-midway-prod.auth.us-east-1.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://www.iris-auto-appointment-scheduler.gsf.amazon.dev/,https://dev-dsk-donchung-1d-f8212f78.us-east-1.amazon.com:3000/",
    "redirectSignOut": "https://www.iris-auto-appointment-scheduler.gsf.amazon.dev/,https://dev-dsk-donchung-1d-f8212f78.us-east-1.amazon.com:3000/",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_dynamodb_all_tables_region": "us-east-1",
  "aws_dynamodb_table_schemas": [
    {
      "tableName": "auto_appointment_carrier_configurations_prod",
      "region": "us-east-1"
    },
    {
      "tableName": "auto_appointment_vendor_details_prod",
      "region": "us-east-1"
    }
  ],
  "aws_mobile_analytics_app_id": "7a0512f5b4a84fa7bf9a11af159d8374",
  "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
